var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "row group-container pt-4 group-main" }, [
          _c("h5", { staticClass: "title-group" }, [_vm._v("Dashboards")]),
          _c(
            "div",
            { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" },
            [
              _c("router-link", { attrs: { to: "/query" } }, [
                _c(
                  "div",
                  { staticClass: "box-part text-center" },
                  [
                    _c("i", {
                      staticClass: "flaticon2-website",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _c("div", { staticClass: "title" }, [
                      _c("h3", [_vm._v("แบบสอบถาม")]),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _c("span", [
                        _vm._v(" แบบสอบถามลูกค้าเกี่ยวกับ"),
                        _c("br"),
                        _vm._v("ความคิดเห็นที่มีต่อประกันชีวิต"),
                      ]),
                    ]),
                    _c("router-link", { attrs: { to: "/query" } }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v("เข้าหน้าแบบสอบถาม"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "box-part text-center",
                on: {
                  click: function ($event) {
                    return _vm.handle.to("commission")
                  },
                },
              },
              [
                _vm._m(0),
                _c("i", {
                  staticClass: "flaticon2-line-chart",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._m(1),
                _vm._m(2),
                _c("a", { attrs: { href: "#" } }, [
                  _vm._v("เข้าหน้าคอมมิชชั่น"),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "box-part text-center",
                on: {
                  click: function ($event) {
                    return _vm.handle.to("cowrie")
                  },
                },
              },
              [
                _vm._m(3),
                _c("i", {
                  staticClass: "flaticon-security",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._m(4),
                _vm._m(5),
                _c("a", { attrs: { href: "#" } }, [
                  _vm._v("เข้าหน้าคำนวนเบี้ย"),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "box-part text-center",
                on: {
                  click: function ($event) {
                    return _vm.handle.to("campaign")
                  },
                },
              },
              [
                _vm._m(6),
                _c("i", {
                  staticClass: "flaticon2-percentage",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._m(7),
                _vm._m(8),
                _c("a", { attrs: { href: "#" } }, [_vm._v("เข้าหน้าแคมเปญ")]),
              ]
            ),
          ]),
          _c("div", { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "box-part text-center",
                on: {
                  click: function ($event) {
                    return _vm.handle.to("profile")
                  },
                },
              },
              [
                _vm._m(9),
                _c("i", {
                  staticClass: "flaticon2-user-1",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._m(10),
                _c("div", { staticClass: "text" }, [
                  !_vm.store.isAuthen
                    ? _c("span", [
                        _vm._v(
                          "\n                                แก้ไขข้อมูลบัญชีผู้ใช้งานและ"
                        ),
                        _c("br"),
                        _vm._v("เปลี่ยนรหัสผ่านเข้าระบบ"),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.store.user.name)),
                        _c("br"),
                        _vm._v(
                          "รหัสตัวแทน : " + _vm._s(_vm.store.user.username)
                        ),
                      ]),
                ]),
                _c("a", { attrs: { href: "#" } }, [
                  _vm._v("เข้าหน้าแก้ไขข้อมูล"),
                ]),
              ]
            ),
          ]),
          _vm.role("root")
            ? _c(
                "div",
                { staticClass: "col-xl-4 col-lg-4 col-md-6 col-sm-12" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "box-part text-center",
                      on: {
                        click: function ($event) {
                          return _vm.handle.to("users")
                        },
                      },
                    },
                    [
                      _vm._m(11),
                      _c("i", {
                        staticClass: "flaticon2-group",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._m(12),
                      _vm._m(13),
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v("เข้าหน้าจัดการตัวแทน"),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-lock" }, [
      _c("i", { staticClass: "flaticon-lock" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("คอมมิชชั่น")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v(" ตารางรายได้และคอมมิชชั่นสำหรับ"),
        _c("br"),
        _vm._v("ตัวแทนใหม่ FYC และ RYC"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-lock" }, [
      _c("i", { staticClass: "flaticon-lock" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("คำนวนเบี้ยประกัน")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v(" ระบบคำนวนเบี้ยประกันตามกรมธรรม์หลัก"),
        _c("br"),
        _vm._v("และสัญญาเพิ่มเติม "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-lock" }, [
      _c("i", { staticClass: "flaticon-lock" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("h3", [_vm._v("แคมเปญ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v(" แคมเปญตัวแทนที่สามารถทำยอดบรรลุ"),
        _c("br"),
        _vm._v("ยอดขายหรือเก็บเบี้ยตามเป้า"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-lock" }, [
      _c("i", { staticClass: "flaticon-lock" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("แก้ไขข้อมูล")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-lock" }, [
      _c("i", { staticClass: "flaticon-lock" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h3", [_vm._v("เพิ่มรหัสตัวแทน")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v(" หน้าเพิ่มและจัดการรหัสตัวแทนสำหรับ"),
        _c("br"),
        _vm._v("เข้าใช้งานระบบ"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }