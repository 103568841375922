<template>
    <div class="dashboard">
        <div class="row">
            <div class="col-lg-12">
                <div class="row group-container pt-4 group-main">
                    <h5 class="title-group">Dashboards</h5>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <router-link to="/query">
                            <div class="box-part text-center">
                                <i class="flaticon2-website" aria-hidden="true"></i>
                                <div class="title">
                                    <h3>แบบสอบถาม</h3>
                                </div>
                                <div class="text">
                                    <span> แบบสอบถามลูกค้าเกี่ยวกับ<br />ความคิดเห็นที่มีต่อประกันชีวิต</span>
                                </div>
                                <router-link to="/query">
                                    <a href="#">เข้าหน้าแบบสอบถาม</a>
                                </router-link>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div @click="handle.to('commission')" class="box-part text-center">
                            <div class="icon-lock">
                                <i class="flaticon-lock"></i>
                            </div>
                            <i class="flaticon2-line-chart" aria-hidden="true"></i>
                            <div class="title">
                                <h3>คอมมิชชั่น</h3>
                            </div>
                            <div class="text">
                                <span> ตารางรายได้และคอมมิชชั่นสำหรับ<br />ตัวแทนใหม่ FYC และ RYC</span>
                            </div>
                            <a href="#">เข้าหน้าคอมมิชชั่น</a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div @click="handle.to('cowrie')" class="box-part text-center">
                            <div class="icon-lock">
                                <i class="flaticon-lock"></i>
                            </div>
                            <i class="flaticon-security" aria-hidden="true"></i>
                            <div class="title">
                                <h3>คำนวนเบี้ยประกัน</h3>
                            </div>
                            <div class="text">
                                <span> ระบบคำนวนเบี้ยประกันตามกรมธรรม์หลัก<br />และสัญญาเพิ่มเติม </span>
                            </div>
                            <a href="#">เข้าหน้าคำนวนเบี้ย</a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div @click="handle.to('campaign')" class="box-part text-center">
                            <div class="icon-lock">
                                <i class="flaticon-lock"></i>
                            </div>
                            <i class="flaticon2-percentage" aria-hidden="true"></i>
                            <div class="title">
                                <h3>แคมเปญ</h3>
                            </div>
                            <div class="text">
                                <span> แคมเปญตัวแทนที่สามารถทำยอดบรรลุ<br />ยอดขายหรือเก็บเบี้ยตามเป้า</span>
                            </div>
                            <a href="#">เข้าหน้าแคมเปญ</a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div @click="handle.to('profile')" class="box-part text-center">
                            <div class="icon-lock">
                                <i class="flaticon-lock"></i>
                            </div>
                            <i class="flaticon2-user-1" aria-hidden="true"></i>
                            <div class="title">
                                <h3>แก้ไขข้อมูล</h3>
                            </div>
                            <div class="text">
                                <span v-if="!store.isAuthen">
                                    แก้ไขข้อมูลบัญชีผู้ใช้งานและ<br />เปลี่ยนรหัสผ่านเข้าระบบ</span
                                >
                                <span v-else>{{ store.user.name }}<br />รหัสตัวแทน : {{ store.user.username }}</span>
                            </div>
                            <a href="#">เข้าหน้าแก้ไขข้อมูล</a>
                        </div>
                    </div>
                    <div v-if="role('root')" class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                        <div @click="handle.to('users')" class="box-part text-center">
                            <div class="icon-lock">
                                <i class="flaticon-lock"></i>
                            </div>
                            <i class="flaticon2-group" aria-hidden="true"></i>
                            <div class="title">
                                <h3>เพิ่มรหัสตัวแทน</h3>
                            </div>
                            <div class="text">
                                <span> หน้าเพิ่มและจัดการรหัสตัวแทนสำหรับ<br />เข้าใช้งานระบบ</span>
                            </div>
                            <a href="#">เข้าหน้าจัดการตัวแทน</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

//* store
import { CONFIGS_DISPATCH } from '@/store/service/configs';

//* plugins
import { get } from 'lodash';
import { state, route, role } from '@/assets/js/tooltify';

export default {
    name: 'dashboard',
    components: {},
    setup(props, Vue) {
        const store = reactive({
            isAuthen: state.getters('USER_AUTHEN_GETTERS'),
            user: state.getters('USER_GETTERS'),
        });

        const handle = {
            to: (to) => {
                state.dispatch(CONFIGS_DISPATCH, { path: 'routeTo', value: to });
                route(Vue, to);
            },
        };

        return { store, get, role, handle };
    },
};
</script>

<style lang="scss" scoped>
.panel-link {
    height: 300px;
    padding: 15px;
    margin: 0px;
}

.link-cowrie {
    background-color: #d31145;
}

.link-query {
    background-image: url('/media/image/region-map.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.box-part {
    position: relative;
    background: #f1f1f1;
    border-radius: 10px;
    padding: 30px 10px;
    margin: 10px 0px;
    border: solid 2px #e2e2e2;

    .text {
        margin: 10px 0px;
        color: #33485e;
    }
    .icon-lock {
        position: absolute;
        right: 5px;
        top: 5px;

        i {
            font-size: 20px;
        }
    }

    h3 {
        font-weight: bold;
        color: #d62455;
    }

    i {
        color: #d31145;
        font-size: 80px;
    }
}
</style>
